import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { Button } from "@/src/components/ui/button";
import GridPattern from "@/src/components/magicui/grid-pattern";
import { cn } from "@/src/lib/utils";
import Header from "../header";
import PlatformsMarquee from "./platforms_marquee";
import TalkToSales from "@/src/app/home/talk_to_sales";
import { ContainerScroll } from "@/src/components/ui/container-scroll-animation";

const Hero = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <header className={`w-full ${isDialogOpen ? 'hidden' : ''}`}>
        <Header />
      </header>

      <div className="relative mx-auto max-w-7xl px-8 lg:px-12 pb-20">
        <GridPattern
          width={70}
          height={70}
          x={-1}
          y={-1}
          strokeDasharray={"4 2"}
          strokeWidth={1}
          className={cn("absolute inset-0 z-20 [mask-image:radial-gradient(750px_circle_at_center,white,transparent)]")}
        />
          <div className="relative flex flex-col items-center text-center gap-8 lg:gap-16 -mt-32 lg:mt-16 mb-24 z-30">
              {/* <div className="flex w-full justify-center lg:justify-end">
              <AutoplayVideo video="landingPageAnimation" size={625} />
            </div> */}

            <div className="flex flex-col">
              <ContainerScroll
                titleComponent={
                  <>
                    <div className="flex flex-col justify-center mb-16 w-full font-sans lg:mt-0 lg:w-full space-y-6 z-30 text-center">
                      <div className="inline-flex space-x-3 items-center justify-center">
                        <Link href={"https://apps.apple.com/us/app/sellraze-list-sell-earn/id6455042085"}>
                          <div className={"mx-auto inline-flex"}>
                            <div className="inline-flex items-center space-x-2 rounded-full border-b-[1px] border-l-[1px] border-r-[1px] border-t-[1px] border-[#076AFF] bg-blue-50 px-2 lg:px-3 py-1.5 text-center font-sans text-xs text-blue-600">
                              <Image src="/star.png" width={100} height={100} alt="Star" className="w-[14px] h-[14px] hidden lg:block" />
                              <div className="text-[10px] lg:text-sm md:tracking-wide font-sfmono font-medium tracking-tighter italic">4.9 on the App Store</div>
                              <Image alt="" width={8} height={8} src="/iconArrowUprightBlue.png" className="my-auto aspect-square w-2" />
                            </div>
                          </div>
                        </Link>
                        <span className="text-[10px] lg:text-sm font-sans font-bold"> Trusted by 5K+ users.</span>
                      </div>

                      <div className="flex w-full flex-col bg-clip-text text-5xl leading-[120%] text-black lg:text-7xl lg:leading-[72px]">
                        <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4 items-center text-center justify-center">
                          <div className="flex-row">
                            <span className="font-sans font-bold text-[#111111]">Selling </span>
                            <span className="font-sans font-bold text-sellRazeBlue">without</span>
                          </div>
                          <span className="font-sans font-bold text-[#111111]">the headaches.</span>
                        </div>
                      </div>

                      <p className="w-full lg:w-2/3 mx-auto font-sfPro text-md lg:text-[24px] font-[590] leading-normal text-neutral-700 align-center justify-center">
                        Use SellRaze’s data-driven tools to create listings in seconds, and to track inventory across your favorite marketplaces, all in one place.
                      </p>
                      <div className="flex flex-col space-y-6 justify-center">
                        <div className="flex flex-row w-full justify-center space-x-4 px-2 lg:px-0">
                          <Button size="lg" variant="default" href="/dashboard" className="flex flex-row space-x-4 items-center">
                            <span className="font-semibold">Get SellRaze free</span>
                            <Image alt="" width={12} height={12} src="/iconArrowUprightWhite.png" className="my-auto aspect-square w-3" />
                          </Button>
                          <TalkToSales setIsDialogOpen={setIsDialogOpen} />
                        </div>
                        <Link 
                          href="/download"
                          className="flex flex-row space-x-5 justify-center items-center opacity-40 hover:opacity-70 transition-all duration-300">
                          <p className="justify-center text-center font-sfmono">
                          AVAILABLE ON
                          </p>
                          <div className="flex flex-row space-x-3 items-center">
                            <Image src="/iconApple.png" alt="apple" width={18} height={18}/>
                            <Image src="/iconMicrosoft.png" alt="microsoft" width={18} height={18}/>
                            <Image src="/iconGlobe.png" alt="globe" width={18} height={18}/>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </>
                }
              >
                <Image
                  src={`/heroPreview.png`}
                  alt="hero"
                  height={1200}
                  width={1400}
                  className="mx-auto rounded-2xl object-cover h-full object-left-top"
                  draggable={false}
                />
              </ContainerScroll>
            </div>
          </div>
        <PlatformsMarquee />
      </div>
    </>
  );
};

export default Hero;
