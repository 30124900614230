"use client";
import Image from "next/image";
import "../styles.scss";
import { useRef } from "react";

interface AutoplayVideoProps {
  video: string;
  size: number;
  props?: string;
}

const AutoplayVideo: React.FC<AutoplayVideoProps> = ({ video, size, props }) => {
  return (
    <div className="video-container mx-auto my-auto block select-none rounded-full">
      <video
        autoPlay
        loop
        muted
        height={size}
        width={size}
        playsInline
        className={`object-cover ${props}`}
        // loading="lazy"    // Lazy load the video
        preload="metadata" // Preload only the metadata
      >
        <source src={`/video/${video}.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default AutoplayVideo;
