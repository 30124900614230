import Image from "next/image";
import Link from "next/link";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/src/components/ui/carousel";
const reviews = [
  {
    name: "@dcraig1313",
    position: "New to selling online",
    content: (
      <div>
        {`"Makes posting on multiple platforms a breeze.  Nice centralized app to do it all.  Love it."`}
      </div>
    ),
    img: "/customers/dcraig.png",
  },
  {
    name: "@uncleroger",
    position: "Regular reseller",
    content: (
      <div>
        {`"Can 100% say this app is great if you wanna save time listing stuff. 
        I had been putting off listing my inventory cause of laziness and got to it last night and it was a breeze. Highly recommend!"`}
      </div>
    ),
    img: "/customers/uncleroger.png",
  },
  {
    name: "@cerebralbox",
    position: "Part-time eBay seller",
    content: (
      <div>
        {`"Love this app! I’ve been using it for months and it’s been hella helpful for me! Made a number of sales and overall just had a fantastic experience."`}
      </div>
    ),
    img: "/customers/cerebralbox.png",
  },
  {
    name: "@floorjack17",
    position: "Side hustler",
    content: (
      <div>
        {`"Easy to use for sure if this old guy can do it. I love how easy it is to set up an item to post to facebook.  
        I listed a mirror and in 1 day have over 500 views and over 10 people wanted to buy(live in a small town so that's the most I ever had when listing) sold within 2 days !!"`}
      </div>
    ),
    img: "/customers/floorjack.png",
  },
  {
    name: "@thewizardking",
    position: "Flipper",
    content: (
      <div>
        {`"The perfect tool for turn key no touch selling alongside fulfillment and logistics automation. The future of reselling is here!"`}
      </div>
    ),
    img: "/customers/theWizardKing.png",
  },
  {
    name: "RookE",
    position: "Full time seller",
    content: (
      <div>
        {`"Constantly adding new features, a complete game changer for resellers!"`}
      </div>
    ),
    img: "/customers/rooke.png",
  },
  {
    name: "Ron R",
    position: "Flipper",
    content: (
      <div>
        {`"SellRaze makes listing your products super easy and super fast.  Their support is phenomenally responsive and always helpful."`}
      </div>
    ),
    img: "/customers/ron.png",
  },
  {
    name: "Jeff",
    position: "Full Time eBay Seller",
    content: (
      <div>
        {`"I source my inventory from pallets & garage sales. SellRaze allows me to list my items 10x faster than before. It's boosted my business's revenue by 34% in the last month."`}
      </div>
    ),
    img: "/customers/jeff.png",
  },
];

const Testimonials = () => {
  return (
    <section className="mx-2 w-full items-center py-24 space-y-16">
      <div className="flex flex-col lg:flex-row px-12 md:px-36 lg:px-52 align-center items-center justify-between mb-24 lg:mb-[2rem] ">
        <div className="text-center lg:text-start font-sans text-[36px] md:text-[42px] lg:text-[52px] font-bold text-neutral-950 leading-[120%]">
          Sellers using SellRaze
          <div className="font-semibold text-[18px] md:text-[21px] lg:text-2xl">See what successful sellers are saying.</div>
        </div>
        <div
          className="p-3 align-center items-center rounded-xl cursor-pointer gap-[10px] hover:bg-neutral-100 transition duration-200"
        >
          <Image src="/twitter.svg" alt="" height="16" width="16" />
          <Link
            className="cursor-pointer bg-sellRazeBlue bg-clip-text font-sans font-semibold text-transparent"
            href="https://x.com/sellraze"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Follow SellRaze
          </Link>
          <Image
            draggable={false}
            className="cursor-pointer"
            src="/chevronGradient.svg"
            width="7"
            height="12"
            alt=""
            priority
          />
        </div>
      </div>
      <Carousel
        opts={{
          align: "start",
          loop: true,
        }}
        className="svg-fix max-w-screen mx-auto w-full select-none flex-row"
      >
        <CarouselContent className="-ml-2">
          {reviews.map((rev, index) => (
            <div key={index} className="pl-4">
              <div className="p-1">
                <div className="inline-flex h-[270px] w-[340px] flex-col items-start justify-start rounded-[22px] border bg-neutral-100 p-6">
                  <div className="flex h-full flex-col items-start justify-between gap-2.5">
                    <div className="font-sans text-[18px] text-base leading-normal text-[#080C10] text-opacity-100">
                      {rev.content}
                    </div>
                    <div className="flex flex-row w-full justify-between items-center">
                      <div>
                        <div className="font-sans text-md font-semibold text-neutral-950">{rev.name}</div>
                        <div className="font-sans text-[14px] font-medium text-neutral-500">{rev.position}</div>
                      </div>
                      <Image
                        draggable={false}
                        alt="User Image"
                        className="h-[42px] w-[42px] rounded-[49px]"
                        height={42}
                        width={42}
                        src={rev.img}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </CarouselContent>
        <CarouselPrevious className="mr-10" />
        <CarouselNext className="mr-10" />
      </Carousel>
    </section>
  );
};

export default Testimonials;
