import { useState, useEffect } from "react";
import Image from "next/image";
import { motion, AnimatePresence } from "framer-motion";
import AppAndPlayStore from "./app_and_play_store";

import PulsatingButton from "@/src/components/magicui/pulsating-button";
import { Skeleton } from "@/src/components/ui/skeleton";
import NumberTicker from "@/src/components/magicui/number-ticker";

const BentoMobile = () => {
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentValue, setCurrentValue] = useState(369);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setCurrentValue(prev => prev === 369 ? 329 : 369);
    }, 5000); // Trigger the animation every 5 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowSkeleton(prev => !prev);
    }, showSkeleton ? 1500 : 2800);

    return () => clearInterval(interval);
  }, [showSkeleton]);

  const bounceVariants = {
    initial: { scale: 1 },
    animate: { scale: 0.95 },
  };

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 300); // Reset after animation
  };

  return (
    <section className="flex justify-center bg-white py-24">
      <div className="flex h-full max-w-[1520px] flex-col items-start justify-start gap-8 px-12 md:px-36 lg:px-52">
        <div className="flex flex-col lg:flex-row justify-between items-center w-full text-center lg:text-start font-sans">
          <p className="bold w-full sm:w-2/3 leading-[120%] text-[36px] md:text-[42px] lg:text-[52px] mb-4 sm:mb-0">

            Mobile convenience.
            <br />
            Seamless management.
          </p>
          <AppAndPlayStore />
        </div>
        <div className="mx-auto grid w-full grid-cols-1 items-center justify-start gap-6 lg:grid-cols-3">
          <div className="mx-auto flex h-[506px] w-[264px] flex-col items-start justify-start rounded-[24px] border border-white bg-lightGray shadow lg:row-span-2">
            <div className="flex flex-col items-start justify-start space-y-1 pt-6 px-6 w-full">
              <h3 className="font-sans text-[21px] font-bold text-black">
                Easy
              </h3>
              <p className="font-sans text-[16px] leading-normal text-neutral-600">
                Add your items by any method that you prefer.
              </p>
            </div>
            <div className="flex w-full items-center justify-end flex-grow">
              <Image
                draggable={false}
                alt="img"
                height={1000}
                width={1000}
                className="h-auto w-full max-w-[240px] object-contain"
                src="/bentoAddItem.png"
              />
            </div>
          </div>
          
          <div className="relative mx-auto flex h-[241px] w-[332px] flex-col items-start justify-start gap-[11px] rounded-[24px] border border-white bg-lightGray p-6">
            <div className="flex flex-col items-start justify-start space-y-1">
              <h3 className="font-sans text-[21px] font-bold text-black">
                Fast
              </h3>
              <p className="font-sans text-[16px] leading-normal text-neutral-600">
                Go from image, text, or barcode to listing in seconds.
              </p>
            </div>
            <div className="flex flex-row w-full items-center align-center justify-center pb-2">
              <AnimatePresence mode="wait">
                {showSkeleton ? (
                  <motion.div
                    key="skeleton"
                    variants={bounceVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ type: "spring", stiffness: 300, damping: 20 }}
                    className="flex flex-row space-x-3 items-center py-4 px-3"
                  >
                    <Skeleton className="h-[48px] w-[48px] rounded-xl bg-zinc-700/10" />
                    <div className="space-y-2">
                      <Skeleton className="h-4 w-[136px] bg-zinc-700/10" />
                      <Skeleton className="h-4 w-[120px] bg-zinc-700/10" />
                    </div>
                    <div className="flex flex-col space-y-1 justify-end items-end">
                      <Skeleton className="h-5 w-[48px] bg-zinc-700/10" />
                      <Skeleton className="h-3 w-[32px] bg-zinc-700/10" />
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    key="content"
                    variants={bounceVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ type: "spring", stiffness: 300, damping: 20 }}
                    className="flex flex-row w-full items-center align-center py-4 px-3 bg-white rounded-2xl space-x-3 shadow-[0_13px_22px_rgba(0,0,0,0.10)]"
                  >
                    <Image
                      draggable={false}
                      alt="img"
                      height={1000}
                      width={1000}
                      className="h-[48px] w-[48px] object-contain rounded-md"
                      src="/bentoJordan.png"
                    />
                    <p className="font-sans font-semibold text-[14px] leading-[1.25] text-neutral-600">
                      Size 10.5 - Jordan 11 Retro High Cherry
                    </p>
                    <div className="flex flex-col space-y-0 text-end">
                      <p className="font-sans font-bold text-[18px] leading-[1.0] text-neutral-800">
                        $740
                      </p>
                      <p className="font-sans font-medium text-[14px] leading-[1.0] text-neutral-800">
                        value
                      </p>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          <div className="relative mx-auto flex h-[241px] w-[332px] flex-col items-start justify-start gap-[11px] rounded-[24px] border border-white bg-lightGray py-6">
            <div className="inline-flex flex-col items-start justify-start space-y-1 px-6 z-10">
              <h3 className="font-sans text-[21px] font-bold text-black">
                Itemized
              </h3>
              <p className="font-sans text-[16px] leading-normal text-neutral-600">
                Learn & monitor the value of your items.
              </p>
            </div>
            <div className="flex flex-row w-full items-center align-center justify-center pb-2 pl-6">
              <div className="relative flex flex-row w-full items-center align-center pl-4 py-4 bg-white shadow-[0_13px_22px_rgba(0,0,0,0.10)] rounded-l-2xl space-x-3 overflow-hidden">
                <Image
                  draggable={false}
                  alt="img"
                  height={1000}
                  width={1000}
                  className="h-[80px] w-[80px] object-contain rounded-md"
                  src="/bentoTissot.png"
                />

                <div className="flex flex-col space-y-1.5">
                  <div className="flex flex-row space-x-1 items-center">
                    <Image
                      draggable={false}
                      alt="img"
                      height={1000}
                      width={1000}
                      className="h-[16px] w-[38px] object-contain"
                      src="/bentoMarketplaces.png"
                    />
                    <p className="font-sans font-regular text-[10px] leading-[1.0] text-dark72">
                      Listed on eBay, Depop, Facebook
                    </p>
                  </div>
                  <p className="font-sans font-semibold text-[14px] leading-[1.0] text-neutral-900">
                    Tissot Seastar 1000 Powermatic 80 40mm..
                  </p>
                  <div className="flex flex-row space-x-1 align-center items-center">
                    <p className={`font-sans font-bold text-[18px] leading-[1.0] ${isAnimating ? 'text-red-500 animate-glow' : 'text-neutral-800'} transition-colors duration-300`}>
                      $<NumberTicker 
                          value={currentValue}
                          startValue={currentValue === 369 ? 369 : 329} 
                          direction={currentValue === 369 ? "down" : "up"}
                          onAnimationStart={() => setIsAnimating(true)}
                          onAnimationEnd={() => setIsAnimating(false)}
                        />
                    </p>
                    <p className="font-sans font-medium text-[14px] leading-[1.0] text-neutral-800">
                      value
                    </p>
                  </div>
                </div>
                
              </div>
              
            </div>
          </div>

          <div className="mx-auto flex h-full w-full max-w-[332px] flex-col items-start justify-between rounded-[24px] border border-white bg-lightGray p-6 filter">
            <div className="inline-flex flex-col items-start justify-start space-y-1">
              <h3 className="font-sans text-[21px] font-bold text-black">Convenient</h3>
              <p className="font-sans text-[16px] leading-normal text-neutral-600">
                Schedule pickup or drop-off at USPS. We also offer shipping labels.
              </p>
            </div>
            <div className="flex flex-row w-full items-center align-center justify-center pb-6 pt-6">

              <motion.div
                variants={bounceVariants}
                animate={isClicked ? "animate" : "initial"}
                transition={{ type: "spring", stiffness: 500, damping: 15 }}
              >
                <PulsatingButton onClick={handleClick}>
                  <div className="flex flex-row w-full items-center align-center space-x-2.5">
                    <Image
                      draggable={false}
                      alt="img"
                      height={1000}
                      width={1000}
                      className="h-[28px] w-[28px] object-contain"
                      src="/bentoHundred.png"
                    />
                    <p className="font-sans font-bold text-xl text-white">Request pickup</p>
                  </div>
                </PulsatingButton>
              </motion.div>
            </div>
          </div>
          <div className="relative mx-auto flex h-full w-full max-w-[332px] flex-col items-start justify-start gap-[11px] rounded-[24px] border border-white bg-lightGray p-6 filter">
            <div className="flex flex-col items-start justify-start space-y-1">
              <h3 className="font-sans text-[21px] font-bold text-black">
                Social
              </h3>
              <p className="font-sans text-[16px] leading-normal text-neutral-600">
                Earn more by inviting your friends.
              </p>
            </div>
            <div className="flex flex-row w-full space-x-[-35px] items-center justify-center">
                <Image
                  draggable={false}
                  alt="img"
                  height={1000}
                  width={1000}
                  className="h-[122px] w-[122px] object-contain shadow-lg rounded-xl transition-transform duration-300 ease-in-out hover:[--rotate-angle:-4deg] hover:cursor-pointer"
                  src="/bentoCoin.png"
                  style={{
                    transform: 'rotate(var(--rotate-angle, 4.95deg))'
                  }}
                />
                <Image
                  draggable={false}
                  alt="img"
                  height={1000}
                  width={1000}
                  className="h-[100px] w-[172px] object-contain transition-transform duration-300 ease-in-out hover:[--rotate-angle:4deg] hover:cursor-pointer"
                  src="/bentoReferral.png"
                  style={{
                    transform: 'rotate(var(--rotate-angle, -4.43deg))'
                  }}
                />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BentoMobile;
